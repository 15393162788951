export const globalMethods = {
  data() {
    return {
      apiURL:
        process.env.NODE_ENV === "production"
          ? "https://api.autoplusrw.com/uploads"
          : "http://localhost/autoplus/public/uploads",
      formTitle: null,
      formSubtitle: null,
      emptyResult: false,
      records: [],
      recordsLength: 0,
      dbResponse: {},
      //pagination
      currentPage: 0,
      rowCountPage: 10,
      totalRows: 0,
      pageRange: 5,
      paginationURL: null,
      responseRef: null,
      refCount: 0,
      alertDefaults: {
        title: null,
        content: null,
        actionType: null,
        actionButton: null,
        classes: null,
        modalOpen: false,
      },
      choosenRow: {},
      trashedImageUrl: null,
      showSingleUpload: false,
      showGalleryUpload: false,
      modalOpen: false,

      // upload
      pickedFile: null,
      uploadingProgress: false,
      progressWidth: 0,
      progressCount: null,
      rcb_document: null,
      uploaded: false,
      filterKey: null,
      publicPath: process.env.BASE_URL,
      avatarColors: [
        "#009f77",
        "#9c27b0",
        "#03a9f4",
        "#F24F23",
        "#C2185B",
        "#004E42",
        "#4D4D4D",
        "#6D24FE",
        "#7D59C0",
      ],
    };
  },
  computed: {
    isUserLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    filteredRecords: function () {
      let filterKey = this.filterKey && this.filterKey.toLowerCase();
      let records = this.records;
      if (filterKey) {
        records = records.filter((row) => {
          return Object.keys(row).some((key) => {
            return String(row[key]).toLowerCase().indexOf(filterKey) > -1;
          });
        });
      }
      return records;
    },
  },
  methods: {
    createColorAvatar() {
      return this.avatarColors[Math.floor(Math.random() * 8 + 1)];
    },
    formData(obj) {
      let formData = new FormData();
      for (let key in obj) {
        if (obj[key] !== null) {
          if (typeof obj[key] === "object")
            formData.append(key, JSON.stringify(obj[key]));
          else formData.append(key, obj[key]);
        }
      }
      return formData;
    },
    incrementIndex(nbr) {
      return nbr + 1;
    },
    signout() {
      this.$store.dispatch("logout").then(() => {
        window.location.replace("/");
      });
    },
    toggleParentClass(elementId, className) {
      let Element = document.getElementById(elementId);
      Element.classList.toggle(className);
    },
    closeAlert() {
      this.alertDefaults.modalOpen = false;
    },
    toggleModalOpen() {
      const elt = document.documentElement;
      if (elt.classList.contains("modal-open"))
        elt.classList.remove("modal-open");
      else elt.classList.add("modal-open");

      const el = document.body;
      if (el.classList.contains("modal-open"))
        el.classList.remove("modal-open");
      else el.classList.add("modal-open");
    },
    toggleModal() {
      this.toggleModalOpen();
      this.modalOpen = !this.modalOpen;
    },
    clearObject(obj) {
      for (let key in obj) {
        if (obj[key] === true) obj[key] = false;
        else if (obj[key] === false) continue;
        else obj[key] = null;
      }
      return obj;
    },
    falseObject(obj, On) {
      for (let key in obj) {
        if (key === On) obj[key] = true;
        else obj[key] = false;
      }
    },
    selectRecord(record) {
      this.choosenRow = record;
    },
    isEqual(arrLen, key) {
      return key === arrLen - 1;
    },
    isEmpty(obj) {
      const isNULL = Object.values(obj).every((x) => x === null);
      return Object.keys(obj).length === 0 || isNULL === true;
    },
    addComma(num) {
      let regexp = /\B(?=(\d{3})+(?!\d))/g;
      return Number(num) ? num.toString().replace(regexp, ",") : null;
    },
    incrementObjectKey(obj, key, max = null) {
      if (max) {
        if (Number(obj[key]) <= max) {
          obj[key] = Number(obj[key]) + 1;
        }
      } else {
        obj[key] = Number(obj[key]) + 1;
      }
    },
    decrementObjectKey(obj, key, min = 1) {
      if (Number(obj[key]) > min) {
        obj[key] = Number(obj[key]) - 1;
      }
    },
    removeComma(str) {
      return Number(str.replace(/,/g, ""));
    },
    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },
    cartesianProduct(arr) {
      return arr.reduce(
        (a, b) => {
          return a
            .map((x) => {
              return b.map((y) => {
                return x.concat(y);
              });
            })
            .reduce((a, b) => {
              return a.concat(b);
            }, []);
        },
        [[]]
      );
    },
    loadPaginationRecords(DispatchpParams = null, singleResponse = false) {
      let actionDispatch =
        DispatchpParams !== null
          ? this.$store.dispatch("postRequest", DispatchpParams)
          : this.$store.dispatch("getRequest", this.paginationURL);
      actionDispatch.then((response) => {
        if (singleResponse === false) {
          this.dbResponse = response.data;
        }
        let responseData =
          this.responseRef === null
            ? response.data
            : response.data[this.responseRef];
        this.$store.state.paginationRecords = response.data;
        this.recordsLength = responseData.length;
        this.generatePagination(responseData);
      });
    },
    generatePagination(rows) {
      this.emptyResult = false;
      this.totalRows = rows.length;
      this.records = rows.slice(
        this.currentPage * this.rowCountPage,
        this.currentPage * this.rowCountPage + this.rowCountPage
      );
      // if the record is empty, go back a page
      if (this.records.length == 0 && this.currentPage > 0) {
        this.pageUpdate(this.currentPage - 1);
        //this.clearAll();
      }
    },
    pageUpdate(pageNumber) {
      this.currentPage = pageNumber;
      this.refresh();
    },
    refresh() {
      let responseData =
        this.responseRef === null
          ? this.dbResponse
          : this.dbResponse[this.responseRef];
      this.generatePagination(responseData);
      //this.loadPaginationRecords();
    },
    chatDate(str) {
      let options = {
        month: "short",
        day: "numeric",
        year: "numeric",
      };
      let today = new Date(str);
      return today.toLocaleDateString("en-US", options);
    },
    chatMiniDate(str) {
      let options = {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      let today = new Date(str);
      return today.toLocaleDateString("en-US", options);
    },
    changePaginationRows(event) {
      this.rowCountPage = event.target.value;
      this.refresh();
    },
    filterProducts(inputAlias = null, divAlias = null) {
      let input, filter, div, a, i, txtValue;
      input =
        inputAlias !== null
          ? document.getElementById(inputAlias)
          : document.getElementById("myInput");
      filter = input.value.toUpperCase();
      div =
        divAlias !== null
          ? document.getElementById(divAlias)
          : document.getElementById("myDropdown");
      a = div.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    },
    FormatDate(givenDate, givenTime = null) {
      let dateString =
        givenTime !== null ? `${givenDate} ${givenTime}` : givenDate;
      let date = new Date(dateString);
      let options = {
        weekday: "short",
        year: "numeric",
        month: "short",
      };
      if (givenTime) {
        return date.toLocaleTimeString("en-US", {
          ...options,
          ...{ day: "numeric", hour: "2-digit", minute: "2-digit" },
        });
      }
      return date.toLocaleDateString("en-US", options);
    },
    getComponentRecords(url) {
      this.$store.state.isLoading = true;
      this.$store.dispatch("getRequest", url).then((response) => {
        this.dbResponse = response.data;
        let responseData = response.data.rows;
        this.recordsLength = responseData.length;
        this.generatePagination(responseData);
        this.$store.state.isLoading = false;
      });
    },
    reloadPage() {
      window.location.reload();
    },
    moneyToWords(givenMoneny) {
      const arr = (x) => Array.from(x);
      const num = (x) => Number(x) || 0;
      //const str = x => String(x);
      const isEmpty = (xs) => xs.length === 0;
      const take = (n) => (xs) => xs.slice(0, n);
      const drop = (n) => (xs) => xs.slice(n);
      const reverse = (xs) => xs.slice(0).reverse();
      const comp = (f) => (g) => (x) => f(g(x));
      const not = (x) => !x;
      const chunk = (n) => (xs) =>
        isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];

      // numToWords :: (Number a, String a) => a -> String
      let numToWords = (n) => {
        let a = [
          "",
          "one",
          "two",
          "three",
          "four",
          "five",
          "six",
          "seven",
          "eight",
          "nine",
          "ten",
          "eleven",
          "twelve",
          "thirteen",
          "fourteen",
          "fifteen",
          "sixteen",
          "seventeen",
          "eighteen",
          "nineteen",
        ];

        let b = [
          "",
          "",
          "twenty",
          "thirty",
          "forty",
          "fifty",
          "sixty",
          "seventy",
          "eighty",
          "ninety",
        ];

        let g = [
          "",
          "thousand",
          "million",
          "billion",
          "trillion",
          "quadrillion",
          "quintillion",
          "sextillion",
          "septillion",
          "octillion",
          "nonillion",
        ];

        // this part is really nasty still
        // it might edit this again later to show how Monoids could fix this up
        let makeGroup = ([ones, tens, huns]) => {
          return [
            num(huns) === 0 ? "" : a[huns] + " hundred ",
            num(ones) === 0 ? b[tens] : (b[tens] && b[tens] + "-") || "",
            a[tens + ones] || a[ones],
          ].join("");
        };

        let thousand = (group, i) =>
          group === "" ? group : `${group} ${g[i]}`;

        if (typeof n === "number") return numToWords(String(n));
        else if (n === "0") return "zero";
        else
          return comp(chunk(3))(reverse)(arr(n))
            .map(makeGroup)
            .map(thousand)
            .filter(comp(not)(isEmpty))
            .reverse()
            .join(" ");
      };
      return numToWords(givenMoneny);
    },
    togglePasswordVisibility(inputId) {
      let elt = document.getElementById(inputId);
      if (elt.type === "password") elt.type = "text";
      else elt.type = "password";
      if (document.getElementById("togglePassword")) {
        document
          .getElementById("togglePassword")
          .classList.toggle("os-icon-eye-off");
      }
    },
    showFilePicker(inputFileId) {
      document.getElementById(inputFileId).click();
    },
    handleEntityImageModify(args = {}) {
      this.$store.dispatch("SetEntityPostData", args).then(() => {
        document.getElementById("global-upload").click();
      });
    },
    isAuthenticate(userRole) {
      return this.$store.state.user.role_id == userRole;
    },
    empty(mixedVar) {
      let undef, key, i, len;
      const emptyValues = [undef, null, false, 0, "", "0"];
      for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixedVar === emptyValues[i]) {
          return true;
        }
      }
      if (typeof mixedVar === "object") {
        for (key in mixedVar) {
          if (Object.prototype.hasOwnProperty.call(mixedVar, key)) {
            return false;
          }
        }
        return true;
      }
      return false;
    },
    handleDeleteAlertModal(args) {
      this.$store.state.recordDeleter.entityName = args.tableName;
      this.$store.state.recordDeleter.url = args.url;
      if (!this.empty(args.media)) {
        this.$store.state.recordDeleter.media = { ...args.media };
      }
      this.$store.state.deleteAlert = {
        title: "Deleting a record",
        content:
          "Are you sure you want to delete this " +
          args.entityIdentifer +
          ": <b>" +
          args.entity +
          "?</b>",
        actionButton: "Yes, delete",
        modalOpen: true,
      };
    },
    deletePaginationRecord() {
      let commitRef = "getRequest";
      let recordDeleter = this.$store.state.recordDeleter;
      let recordDeleterUrl = recordDeleter.url;
      let dispatchArgs = recordDeleterUrl;
      if (
        !this.empty(recordDeleter.media) &&
        !this.isEmpty(recordDeleter.media)
      ) {
        commitRef = "postRequest";
        dispatchArgs = {
          url: recordDeleterUrl,
          formData: this.formData({ media: recordDeleter.media }),
        };
      }
      this.$store.state.activeBtn = "deletingRecord";
      this.$store.dispatch(commitRef, dispatchArgs).then((response) => {
        if (!response.data.error) {
          this.$store.state.deleteAlert.modalOpen = false;
          window.location.reload();
        }
      });
    },
    handleDeletePopOver(popOverRef = null) {
      this.$store.dispatch("launchPopOver", popOverRef);
    },
    generatePassword() {
      let length = 8,
        charset =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
      for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },
  },
};
