<template>
  <div>
    <div
      class="modal fade show"
      style="z-index: 999999999; display: block"
      role="dialog"
       @click.self="$emit('close')"
    >
      <div class="modal-dialog modal-dialog-centered" :class="[modalSize]">
        <div class="modal-content">
          <div class="modal-header" v-if="showHeader">
            <h5 class="modal-title">
              <slot name="head"></slot>
            </h5>
            <a
              href="javascript:void(0)"
              class="close"
              @click.prevent="$emit('close')"
            >
              <em class="icon ni ni-cross"></em
            ></a>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer bg-light" v-if="showFooter">
            <slot name="foot"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" @click.prevent="$emit('close')"></div>
  </div>
</template>
<script>
export default {
  name: "HViewModal",
  props: {
    modalSize: String,
    showFooter: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style scoped>
.modal-xg {
  max-width: 1000px !important;
}
.modal-header {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}
</style>
