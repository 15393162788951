import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import store from "../store";
const options = {
  latencyThreshold: 200,
  router: true,
  http: false,
};
Vue.use(NProgress, options);
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    /*
    | Routers for Index
    */
    {
      path: "*",
      name: "error404",
      component: () =>
        import(
          /* webpackChunkName: "adminroots" */ "../admin/views/Error404.vue"
        ),
    },
    {
      path: "",
      name: "Landing",
      component: () =>
        import(
          /* webpackChunkName: "autoplusLanding" */ "../index/views/Index.vue"
        ),
      meta: {
        title: "Home",
      },
    },
    {
      path: "/",
      name: "Home",
      component: () =>
        import(
          /* webpackChunkName: "autoplusLanding" */ "../index/views/Index.vue"
        ),
      meta: {
        title: "Home",
      },
    },
    {
      path: "/services",
      name: "PublicServices",
      component: () =>
        import(
          /* webpackChunkName: "autoplusLanding" */ "../index/views/Services.vue"
        ),
      meta: {
        title: "Services",
      },
    },
    {
      path: "/products",
      name: "PublicProducts",
      component: () =>
        import(
          /* webpackChunkName: "autoplusLanding" */ "../index/views/Products.vue"
        ),
      meta: {
        title: "Products",
      },
    },
    {
      path: "/contact",
      name: "PublicContact",
      component: () =>
        import(
          /* webpackChunkName: "autoplusLanding" */ "../index/views/Contact.vue"
        ),
      meta: {
        title: "Contact Us",
      },
    },
    {
      path: "/about",
      name: "PublicAbout",
      component: () =>
        import(
          /* webpackChunkName: "autoplusLanding" */ "../index/views/About.vue"
        ),
      meta: {
        title: "About Us",
      },
    },
    {
      path: "/gallery",
      name: "PublicGallery",
      component: () =>
        import(
          /* webpackChunkName: "autoplusLanding" */ "../index/views/Gallery.vue"
        ),
      meta: {
        title: "Gallery",
      },
    },
    {
      path: "/account/login",
      name: "SignIn",
      component: () =>
        import(
          /* webpackChunkName: "autoplusLanding" */ "../index/views/Login.vue"
        ),
      meta: {
        title: "Login",
      },
    },
    {
      path: "/account/sign-up",
      name: "Register",
      component: () =>
        import(
          /* webpackChunkName: "autoplusLanding" */ "../index/views/Register.vue"
        ),
      meta: {
        title: "Sign up",
      },
    },
    {
      path: "/admin/login",
      name: "login",
      component: () =>
        import(/* webpackChunkName: "adminroots" */ "../admin/views/Login.vue"),
      meta: {
        title: "Login",
      },
    },
    {
      path: "/admin/dashboard",
      name: "dashboard",
      component: () =>
        import(
          /* webpackChunkName: "adminroots" */ "../admin/views/Dashboard.vue"
        ),
      meta: {
        title: "Dashboard",
        requiresAdminAuth: true,
      },
    },
    {
      path: "/admin/partners",
      name: "Partners",
      component: () =>
        import(
          /* webpackChunkName: "adminroots" */ "../admin/views/Partners.vue"
        ),
      meta: {
        title: "Partners",
        requiresAdminAuth: true,
      },
    },
    {
      path: "/admin/banners",
      name: "Banners",
      component: () =>
        import(
          /* webpackChunkName: "adminroots" */ "../admin/views/Banners.vue"
        ),
      meta: {
        title: "Banners",
        requiresAdminAuth: true,
      },
    },
    {
      path: "/admin/team-member",
      name: "Members",
      component: () =>
        import(/* webpackChunkName: "adminroots" */ "../admin/views/Team.vue"),
      meta: {
        title: "Members",
        requiresAdminAuth: true,
      },
    },
    {
      path: "/admin/services",
      name: "Services",
      component: () =>
        import(
          /* webpackChunkName: "adminroots" */ "../admin/views/Services.vue"
        ),
      meta: {
        title: "Services",
        requiresAdminAuth: true,
      },
    },
    {
      path: "/admin/gallery",
      name: "Gallery",
      component: () =>
        import(
          /* webpackChunkName: "adminroots" */ "../admin/views/Gallery.vue"
        ),
      meta: {
        title: "Gallery",
        requiresAdminAuth: true,
      },
    },
    {
      path: "/admin/values",
      name: "Values",
      component: () =>
        import(
          /* webpackChunkName: "adminroots" */ "../admin/views/Values.vue"
        ),
      meta: {
        title: "Company Values",
        requiresAdminAuth: true,
      },
    },
    {
      path: "/admin/about",
      name: "About",
      component: () =>
        import(/* webpackChunkName: "adminroots" */ "../admin/views/About.vue"),
      meta: {
        title: "About Company",
        requiresAdminAuth: true,
      },
    },
    {
      path: "/admin/products",
      name: "Products",
      component: () =>
        import(
          /* webpackChunkName: "adminroots" */ "../admin/views/Products.vue"
        ),
      meta: {
        title: "Products",
        requiresAdminAuth: true,
      },
    },
    {
      path: "/admin/requests",
      name: "Requests",
      component: () =>
        import(
          /* webpackChunkName: "adminroots" */ "../admin/views/Requests.vue"
        ),
      meta: {
        title: "Requests",
        requiresAdminAuth: true,
      },
    },
    {
      path: "/admin/general-info",
      name: "GeneralInfo",
      component: () =>
        import(
          /* webpackChunkName: "adminroots" */ "../admin/views/General.vue"
        ),
      meta: {
        title: "General Information",
        requiresAdminAuth: true,
      },
    },
    {
      path: "/admin/users",
      name: "Users",
      component: () =>
        import(
          /* webpackChunkName: "adminroots" */ "../admin/views/Users.vue"
        ),
      meta: {
        title: "Users",
        requiresAdminAuth: true,
      },
    },
  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  //const token = localSto
  if (to.matched.some((record) => record.meta.requiresUserAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    window.location.replace("/admin/login");
  } else if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    window.location.replace("/dashboard");
  } else {
    next();
  }
});

const DEFAULT_TITLE = "AutoPlus Rwanda";
router.afterEach((to) => {
  NProgress.done();
  Vue.nextTick(() => {
    document.title = to.meta.title + " | " + DEFAULT_TITLE || DEFAULT_TITLE;
    if (store.state.drawerOpen) store.state.drawerOpen = false;
    [...document.querySelectorAll("div.dropdown-menu")].forEach((elt) => {
      elt.classList.remove("show");
    });
    if (document.querySelector("#navbarMainContent"))
      document.querySelector("#navbarMainContent").classList.remove("show");
    if (document.getElementById("userAside"))
      document.getElementById("userAside").classList.remove("content-active");
  });
});

export default router;
