<template>
  <div>
    <div
      class="modal fade show"
      id="modal-default"
      tabindex="-1"
      role="dialog"
      style="display: block"
    >
      <div
        class="modal-dialog modal- modal-dialog-centered modal-"
        role="document"
      >
        <div class="modal-content rounded-0" style="border-color: transparent">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ modalTitle }}
            </h5>
            <a
              href="javascript:void(0)"
              class="close"
              @click.prevent="$emit('close')"
            >
              <em class="icon ni ni-cross"></em
            ></a>
          </div>

          <div class="modal-body text-left">
            <div v-html="modalContent"></div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              :class="'mr-auto ' + actionButtonClasses"
              @click="$emit('accept-alert')"
            >
              <template
                v-if="
                  $store.state.isLoading &&
                  $store.state.activeBtn === 'deletingRecord'
                "
              >
                <span
                  class="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span>Please wait...</span>
              </template>
              <template v-else>{{ actionButton }}</template>
            </button>
            <button
              v-if="!singleButton"
              type="button"
              class="btn btn-dark ms-auto"
              @click="$emit('close')"
            >
              {{ closeButton }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show"></div>
  </div>
</template>
<script>
export default {
  name: "HViewAlert",
  props: {
    modalTitle: String,
    modalContent: {
      type: String,
      required: true,
    },
    singleButton: {
      type: Boolean,
      default: false,
    },
    actionButton: {
      type: String,
      required: true,
    },
    actionButtonClasses: {
      type: String,
      required: true,
    },
    closeButton: {
      type: String,
      default: "close",
    },
  },
  created() {
    this.toggleModalOpen();
  },
  destroyed() {
    this.toggleModalOpen();
  },
};
</script>
