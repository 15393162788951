import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
const baseURL =
  process.env.NODE_ENV === "production"
    ? "https://api.autoplusrw.com/v1/"
    : "http://localhost/autoplus/public/api/";
axios.defaults.baseURL = baseURL;
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: {},
    currentProfile: {},
    isLoading: false,
    activeBtn: null,
    drawerOpen: false,
    paginationRecords: [],
    projectMediaPath: "uploads/projects",
    entityImagePost: {
      record_id: null,
      url: null,
    },
    deleteAlert: {
      title: null,
      content: null,
      actionType: null,
      actionButton: null,
      classes: null,
      modalOpen: false,
    },
    currentDeletePopOver: null,
    recordDeleter: {
      entityName: null,
      url: null,
      media: {
        recordImage: null,
        galleryTable: null,
        mediaPath: null,
        galleryParentRef: null,
      },
    },
    currentContent: {},
    isEditingMode: false,
    responseData: {},
    settings:{ social:{} }
  },

  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, playload) {
      state.status = "success";
      state.token = playload.token;
      state.user = playload.user;
      state.activeBtn = null;
    },
    request_success(state) {
      state.activeBtn = null;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
    },
    setting_postdata(state, args) {
      state.entityImagePost = {
        record_id: args.record_id,
        url: args.url,
      };
    },
    set_contents(state, contents) {
      state.cmsContents = contents;
    },
    edit_content(state, content) {
      state.currentContent = content;
      state.isEditingMode = true;
    },
    set_current_user(state, user) {
      state.user = user;
    },
    set_current_profile(state, user) {
      state.currentProfile = user;
    },
    set_qualification(state, qualification) {
      state.currentProfile.core_qualification.push(qualification);
    },
    set_popover_name(state, ref) {
      state.currentDeletePopOver = ref;
    },
    set_db_response(state, responseData) {
      if (responseData) {
        state.responseData = { ...state.responseData, ...responseData };
      }
    },
    SET_SETTINGS(state, data){
      state.settings = data;
    }
  },
  actions: {
      setQualification({ commit }, qualification) {
      return new Promise((resolve) => {
        commit("set_qualification", qualification);
        resolve();
      });
    },
    launchPopOver({ commit }, givenName) {
      return new Promise((resolve) => {
        commit("set_popover_name", givenName);
        resolve();
      });
    },
    setCurrentUser({ commit }, user) {
      return new Promise((resolve) => {
        commit("set_current_user", user);
        resolve();
      });
    },
    setCurrentProfile({ commit }, user) {
      return new Promise((resolve) => {
        commit("set_current_profile", user);
        resolve();
      });
    },
    SetEntityPostData({ commit }, args) {
      return new Promise((resolve) => {
        commit("setting_postdata", args);
        resolve();
      });
    },
    setContents({ commit }, contents) {
      return new Promise((resolve) => {
        commit("set_contents", contents);
        resolve();
      });
    },
    editContent({ commit }, content) {
      return new Promise((resolve) => {
        commit("edit_content", content);
        resolve();
      });
    },
    authRequest({ commit }, dataParams) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios({
          url: dataParams.url,
          data: dataParams.formData,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.access_token;
            if (token) {
              localStorage.setItem("token", token);
              axios.defaults.headers.common["Authorization"] = token;
              commit("auth_success", { token: token, user: resp.data.user });
            }
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },

    logUser({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios
          .post("ap/log-user/", user)
          .then((resp) => {
            const token = resp.data.token;
            if (token) {
              localStorage.setItem("token", token);
              axios.defaults.headers.common["Authorization"] = token;
              commit("auth_success", token);
            }
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },

    logAdmin({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        axios
          .post("app/login/", user)
          .then((resp) => {
            const token = resp.data.token;
            localStorage.setItem("token", token);
            axios.defaults.headers.common["Authorization"] = token;
            commit("auth_success", token);
            resolve(resp);
          })
          .catch((err) => {
            commit("auth_error");
            localStorage.removeItem("token");
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        commit("logout");
        localStorage.removeItem("token");
        delete axios.defaults.headers.common["Authorization"];
        resolve();
      });
    },
    postRequest({ commit }, dataParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(dataParams.url, dataParams.formData)
          .then((resp) => {
            commit("request_success");
            commit("set_db_response", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.dispatch("logout").then(() => {
                window.location.replace("/");
              });
            }
            commit("auth_error");
            reject(err);
          });
      });
    },
    getRequest({ commit }, getURL) {
      return new Promise((resolve, reject) => {
        axios
          .get(getURL)
          .then((resp) => {
            commit("request_success");
            commit("set_db_response", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            if (err.response.status == 401) {
              this.dispatch("logout").then(() => {
                window.location.replace("/");
              });
            }
            commit("auth_error");
            reject(err);
          });
      });
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    isEditingMode: (state) => !!state.isEditingMode,
    user: (state) => state.user,
    paginationRecords: (state) => state.paginationRecords,
    currentProfile: (state) => state.currentProfile,
  },
});
