<template>
  <div class="qs-container">
    <div class="hview-popover above">
      <div class="py-2" v-html="warning"></div>
      <div class="d-flex w-100">
        <hview-button
          load-text=""
          caption="Proceed"
          button-type="button"
          @btn-role="$emit('confirmed')"
          classes="btn btn-sm btn-primary"
          :disabler="false"
          activator="popover_requesting"
        ></hview-button>

        <button
          class="btn btn-sm btn-secondary ml-auto"
          type="button"
          @click="handleDeletePopOver(null)"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HviewConfirmhview-popover",
  props: {
    warning: {
      type: String,
      default: null
    }
  }
};
</script>
<style lang="css" scoped>
.hview-popover b,
.hview-popover strong {
  font-weight: 700 !important;
}
.qs-container {
  position: relative;
  text-align: center;
}
.qs-container .hview-popover {
  background-color: #fff;
  border-radius: 5px;
  bottom: 26px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  color: #000;
  display: block;
  font-size: 12px;
  font-family: "Helvetica", sans-serif;
  left: -95px;
  padding: 7px 10px;
  position: absolute;
  width: 200px;
  z-index: 4;
}
.qs-container .hview-popover::before {
  border-top: 7px solid #fff;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  bottom: -7px;
  content: "";
  display: block;
  left: 50%;
  margin-left: -7px;
  position: absolute;
}
.qs-container .hview-popover::after {
  display: block;
  -webkit-animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
  -moz-animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
  -ms-animation: fade-in 0.3s linear 1, move-up 0.3s linear 1;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-ms-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes move-up {
  from {
    bottom: 30px;
  }
  to {
    bottom: 42px;
  }
}
@-moz-keyframes move-up {
  from {
    bottom: 30px;
  }
  to {
    bottom: 42px;
  }
}
@-ms-keyframes move-up {
  from {
    bottom: 30px;
  }
  to {
    bottom: 42px;
  }
}
</style>
